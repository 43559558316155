import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { mdiAbacus, mdiAccountOutline, mdiCogOutline, mdiDnsOutline } from '@mdi/js'
import { subject } from '@casl/ability'
import { PRIVATE } from '@/plugins/acl/const'

export default function useJobList() {
  const vm = getCurrentInstance().proxy

  const jobs = ref([])
  const choices = ref({})
  const recruiterChoices = ref([])

  const stageFilter = ref([])
  const workflowFilter = ref([])
  const cityFilter = ref([])
  const assignedToFilter = ref([])

  const tableColumns = [
    { text: 'title', value: 'title', class: 'ps-7 text-uppercase' },
    { text: 'workflow', value: 'workflowTitle', class: 'text-uppercase' },
    { text: 'city', value: 'city', class: 'text-uppercase' },
    { text: 'country', value: 'country', class: 'text-uppercase' },
    { text: 'createdBy', value: 'createdBy', sortable: false, class: 'text-uppercase' },
    { text: 'candidates', value: 'candidatesAmount', align: 'center', class: 'text-uppercase' },
    { text: 'status', value: 'stage', class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalJobsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const overview = ref([])

  const { getJobsFilters, getAllJobs, getJobsOverview } = useActions('jobs', [
    'getJobsFilters',
    'getAllJobs',
    'getJobsOverview',
  ])
  const { getRecruitersChoices } = useActions('company', ['getRecruitersChoices'])

  const fetchJobsOverview = async () => {
    overview.value = await getJobsOverview()
  }

  const fetchJobs = async () => {
    loading.value = true
    const { data, success } = await getAllJobs({
      stage: stageFilter.value,
      assignedTo: assignedToFilter.value,
      city: cityFilter.value,
      workflow: workflowFilter.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el
          .replaceAll('.', '__')
          .replace('candidatesAmount', 'candidates_amount')
          .replace('workflowTitle', 'workflow__title')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        jobs.value = data.results.map((object) => subject(PRIVATE.ATS.JOB, object))
        totalJobsListTable.value = data.count
      } else {
        jobs.value = data.map((object) => subject(PRIVATE.ATS.JOB, object))
        totalJobsListTable.value = data.length
      }
      loading.value = false
    }

    choices.value = await getJobsFilters({
      stage: stageFilter.value,
      assignedTo: assignedToFilter.value,
      city: cityFilter.value,
      workflow: workflowFilter.value,
    })
    choices.value.stageChoices = choices.value.stageChoices.map((stage) => {
      return {
        ...stage,
        text: vm.$t(stage.text),
      }
    })
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchJobs()
    }, 1000)
  }

  watch([stageFilter, workflowFilter, cityFilter, assignedToFilter, options], async () => {
    await fetchJobs()
  })

  const fetchRecruitersChoices = async () => {
    recruiterChoices.value = await getRecruitersChoices()
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveJobStageColor = (stage) => {
    if (stage === 'draft') return { text: vm.$t(stage), color: 'secondary' }
    if (stage === 'ongoing') return { text: vm.$t(stage), color: 'primary' }
    if (stage === 'finished') return { text: vm.$t(stage), color: 'accent' }
    if (stage === 'archived') return { text: vm.$t(stage), color: 'primary_c5' }

    return { text: '-/-', color: 'primary' }
  }

  const resolveJobsTotalIcon = (title) => {
    if (title === 'total') return { icon: mdiCogOutline, color: 'secondary' }
    if (title === 'candidatesInOngoingJobs') return { icon: mdiDnsOutline, color: 'primary' }
    if (title === 'ongoing') return { icon: mdiAccountOutline, color: 'success' }
    if (title === 'totalCandidates') return { icon: mdiAbacus, color: 'success' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    fetchJobs,
    fetchRecruitersChoices,
    fetchJobsOverview,
    resolveJobStageColor,
    resolveJobsTotalIcon,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalJobsListTable,
    loading,
    options,
    overview,
    jobs,
    choices,
    recruiterChoices,
    stageFilter,
    workflowFilter,
    cityFilter,
    assignedToFilter,
  }
}
