import { ref, watch } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiAccountOutline, mdiCogOutline, mdiDnsOutline } from '@mdi/js'
import { subject } from '@casl/ability'
import { PRIVATE } from '@/plugins/acl/const'

export default function useCandidateList() {
  const candidates = ref([])
  const choices = ref({})
  const selected = ref([])

  const stageFilter = ref([])

  const tableColumns = [
    { text: 'name', value: 'name', class: 'text-uppercase' },
    { text: 'status', value: 'stage', class: 'text-uppercase' },
    { text: 'step', value: 'step', class: 'text-uppercase' },
    { text: 'gdprStatus', value: 'gdprStatus', class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalCandidatesListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const userTotalLocal = ref([])
  const overview = ref([])

  const { getJobCandidatesFilters, getJobCandidates, getJobCandidatesOverview } = useActions('jobs', [
    'getJobCandidatesFilters',
    'getJobCandidates',
    'getJobCandidatesOverview',
  ])

  const { jobData } = useState('jobs', ['jobData'])

  const fetchCandidatesOverview = async () => {
    overview.value = await getJobCandidatesOverview(jobData.value.id)
  }

  const fetchCandidates = async () => {
    loading.value = true
    if (!jobData.value.id) return
    const { data, success } = await getJobCandidates({
      jobId: jobData.value.id,
      stage: stageFilter.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el
          .replaceAll('.', '__')
          .replace('gdprStatus', 'gdpr_status')
          .replace('name', 'candidate__user__last_name')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        candidates.value = data.results
        totalCandidatesListTable.value = data.count
      } else {
        candidates.value = data
        totalCandidatesListTable.value = data.length
      }
      candidates.value.forEach((candidate) => {
        // eslint-disable-next-line no-param-reassign
        if (['rejected', 'hired'].includes(candidate.stage)) candidate.disabled = true
        subject(PRIVATE.ATS.JOB_CANDIDATE, candidate)
      })
      loading.value = false
    }

    choices.value = await getJobCandidatesFilters({
      jobId: jobData.value.id,
    })
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchCandidates()
    }, 1000)
  }

  watch([stageFilter, options], async () => {
    await fetchCandidates()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCandidateStageColor = (stage) => {
    if (stage === 'new') return { text: 'new', color: 'info' }
    if (stage === 'in_progress') return { text: 'inProgress', color: 'primary_c5' }
    if (stage === 'hired') return { text: 'hired', color: 'success' }
    if (stage === 'rejected') return { text: 'rejected', color: 'error' }

    return { text: '-/-', color: 'primary' }
  }

  const resolveGDPRStatusColor = (status) => {
    if (status === 'valid') return { text: 'valid', color: 'success' }
    if (status === 'expired') return { text: 'expired', color: 'warning' }
    if (status === 'anonymized') return { text: 'anonymized', color: 'accent' }
    if (status === 'autoAnonymizationDisabled') return { text: 'Auto anonymization disabled', color: 'secondary' }

    return { text: '-/-', color: 'primary' }
  }

  const resolveCandidatesTotalIcon = (title) => {
    if (title === 'total') return { icon: mdiCogOutline, color: 'secondary' }
    if (title === 'new') return { icon: mdiDnsOutline, color: 'primary' }
    if (title === 'in_progress') return { icon: mdiAccountOutline, color: 'success' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    fetchCandidates,
    fetchCandidatesOverview,
    resolveCandidateStageColor,
    resolveGDPRStatusColor,
    resolveCandidatesTotalIcon,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalCandidatesListTable,
    loading,
    options,
    userTotalLocal,
    overview,
    candidates,
    choices,
    selected,
    stageFilter,
  }
}
