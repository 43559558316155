<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(jobData.offerIds, jobData.id, loading, {
                name: 'apps-ats-job-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(jobData.offerIds, jobData.id, loading, {
                name: 'apps-ats-job-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-between align-center w-full mb-2">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(jobData.offerIds, jobData.id) || loading"
              @click="
                goToPrevious(jobData.offerIds, jobData.id, loading, {
                  name: 'apps-ats-job-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <span class="text-center">{{ jobData.title }}</span>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(jobData.offerIds, jobData.id) || loading"
              @click="
                goToNext(jobData.offerIds, jobData.id, loading, {
                  name: 'apps-ats-job-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
          <v-chip
            label
            small
            :color="resolveJobStageColor(jobData.stage).color"
            :class="`v-chip-light-bg ${
              resolveJobStageColor(jobData.stage).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
            >{{ resolveJobStageColor(jobData.stage).text }}</v-chip
          >
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('assignedTo') }}:</span>
              <span class="text--secondary">{{ recruiterText(jobData.assignedTo) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('sharedWith') }}:</span>
              <span v-if="jobData.sharedWith && jobData.sharedWith.length > 0" class="text--secondary">{{
                jobData.sharedWith.map((rec) => recruiterText(rec.id)).join(', ')
              }}</span>
              <span v-else class="text--secondary">-/-</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('title') }}:</span>
              <span class="text--secondary">{{ jobData.title }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-1">
              <span>
                <span class="font-weight-medium me-2">{{ $t('description') }}:</span>
                <span class="text-pre-wrap">
                  <v-clamp class="text--secondary" autoresize :max-lines="3"
                    >{{ jobData.description }}
                    <template #after="{ expanded, clamped, toggle }">
                      <v-chip
                        v-if="clamped || expanded"
                        class="ml-1 disable-min-height--chip"
                        x-small
                        color="primary"
                        outlined
                        @click="toggle"
                      >
                        {{ clamped ? $t('expand') : $t('minimize') }}
                      </v-chip>
                    </template>
                  </v-clamp>
                </span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span>
                <span class="font-weight-medium me-2">{{ $t('city') }}:</span>
                <span class="text--secondary">{{ jobData.city }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span>
                <span class="font-weight-medium me-2">{{ $t('country') }}:</span>
                <span class="text--secondary">{{ jobData.country }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('minSalary') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobData.minSalary) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('maxSalary') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobData.maxSalary) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdBy') }}:</span>
              <span class="text--secondary">{{ recruiterText(jobData.createdBy) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(jobData.createdAt) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('updatedAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(jobData.updatedAt) }}</span>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="my-2">
            <v-col v-if="$can(ACTIONS.DELETE, jobData)" cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn small color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2" size="16">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">{{
                        $t('confirmationModalUndoneQuestion')
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteJobHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="$can(ACTIONS.DUPLICATE, jobData)" cols="auto">
              <v-btn small color="primary" outlined @click="duplicateJobHandler">
                <v-icon class="me-2" size="16">{{ icons.mdiContentDuplicate }}</v-icon>
                <span v-t="'duplicate'" />
              </v-btn>
            </v-col>
            <v-col
              v-if="$can(ACTIONS.JOBS.MANAGE_JOB, jobData) || $can(ACTIONS.JOBS.MANAGE_JOB_STAGE, jobData)"
              cols="auto"
            >
              <v-btn small color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2" size="16">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="$can(ACTIONS.JOBS.MANAGE_JOB, jobData)">
            <h2 v-t="'applyForm'" class="text-xl font-weight-semibold mb-2 mt-4" />
            <v-divider></v-divider>
            <v-autocomplete
              v-model="applyFormSelectedSource"
              class="mt-4"
              :items="sourcesChoices"
              :label="$t('source')"
              :placeholder="$t('source')"
              item-value="id"
              item-text="source"
              outlined
              dense
              :error="!jobData.enableApplyForm"
              :error-messages="!jobData.enableApplyForm ? [$t('applyFormError')] : []"
              hide-details="auto"
              @change="setApplyFormUrl"
            >
              <template v-slot:append-outer>
                <v-fade-transition leave-absolute>
                  <v-icon
                    v-if="applyFormSelectedSource"
                    :key="copyDynamicIcon"
                    size="25"
                    :color="copyDynamicIcon === 'copy' ? 'primary' : 'success'"
                    :disabled="!(jobData.enableApplyForm && !!applyFormSelectedSource.length)"
                    @click="copySource"
                  >
                    {{ copyDynamicIcon === 'copy' ? icons.mdiContentCopy : icons.mdiCheck }}
                  </v-icon>
                </v-fade-transition>
              </template>
            </v-autocomplete>
            <v-slide-y-transition leave-absolute>
              <span v-if="applyFormUrl && applyFormUrl.length" class="text-xs">{{ applyFormUrl }}</span>
            </v-slide-y-transition>
          </div>

          <h2 class="text-xl font-weight-semibold mb-2 mt-4">{{ $t('workflow') }} - {{ jobData.workflow.title }}</h2>
          <v-divider></v-divider>
          <v-stepper vertical class="my-2 custom-header stepper-custom-preview" flat non-linear>
            <template v-for="(step, index) in jobData.workflow.steps">
              <v-stepper-step
                :key="`${index}-step`"
                :step="index"
                color="primary"
                complete
                editable
                :edit-icon="icons.mdiCircleOutline"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">{{ index + 1 }}</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">{{ step.name }}</span>
                    <span class="text--secondary text-xs">{{ step.description }}</span>
                  </div>
                </div>
              </v-stepper-step>
              <v-stepper-content v-if="step.stepDayLimit !== null" :key="`${index}-step-content`" :step="index">
                <span class="font-weight-medium me-2">{{ $t('stepDayLimit', { postfix: ':' }) }}</span>
                <span class="text--secondary">{{ step.stepDayLimit }}</span>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-card-text>
      </v-card>

      <job-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :job-data.sync="jobData"
        :recruiter-choices="recruiterChoices"
        @refetch-candidates="$emit('refetch-candidates')"
      ></job-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiTrashCanOutline,
  mdiContentDuplicate,
  mdiCircleOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import VClamp from 'vue-clamp'

import { formatDateToMonthShort, emptyValueFormatter } from '@core/utils/filter'
import { useRouter } from '@core/utils'

import { useClipboard } from '@vueuse/core'
import jobsUtils from '@/utils/jobsUtils'
import JobBioEdit from './JobBioEdit.vue'
import useJobList from '@/views/apps/ats/jobs/job-list/useJobList'
import { ACTIONS } from '@/plugins/acl/const'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    VClamp,
    JobBioEdit,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const {
      fetchRecruitersChoices,
      fetchSourcesChoices,
      recruiterText,

      sourcesChoices,
      recruiterChoices,
    } = jobsUtils()

    const { resolveJobStageColor } = useJobList()

    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const applyFormUrl = ref('')
    const applyFormSelectedSource = ref('')

    const { router } = useRouter()

    const { deleteJob, duplicateJob, getApplyFormSourceLink } = useActions('jobs', [
      'deleteJob',
      'duplicateJob',
      'getApplyFormSourceLink',
    ])
    const { jobData } = useState('jobs', ['jobData'])

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    const deleteJobHandler = async () => {
      await deleteJob(jobData.value.id)
      router.push({ name: 'apps-ats-jobs-list' })
    }

    const duplicateJobHandler = async () => {
      await duplicateJob(jobData.value.id)
      router.push({ name: 'apps-ats-jobs-list' })
    }

    const setApplyFormUrl = async () => {
      applyFormUrl.value = await getApplyFormSourceLink({
        jobId: jobData.value.id,
        source: applyFormSelectedSource.value,
      })
    }

    // ------- Clipboard -------

    const { copy, isSupported } = useClipboard()
    const copyDynamicIcon = ref('copy')

    const showSnackbarCopyNotSupported = ref(false)
    const showSnackbarCopiedSuccessfully = ref(false)

    const copySource = async () => {
      if (!isSupported) showSnackbarCopyNotSupported.value = true
      else {
        await copy(applyFormUrl.value)
        showSnackbarCopiedSuccessfully.value = true
        copyDynamicIcon.value = 'copied'
        setTimeout(() => {
          copyDynamicIcon.value = 'copy'
          applyFormUrl.value = ''
          applyFormSelectedSource.value = ''
        }, 5000)
      }
    }

    onMounted(async () => {
      await fetchRecruitersChoices()
      await fetchSourcesChoices()
    })

    return {
      recruiterText,
      formatDateToMonthShort,
      deleteJobHandler,
      duplicateJobHandler,
      emptyValueFormatter,
      resolveJobStageColor,
      setApplyFormUrl,
      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      jobData,
      isBioDialogOpen,
      isDeleteDialogOpen,
      applyFormSelectedSource,
      applyFormUrl,
      sourcesChoices,
      recruiterChoices,

      // ------ Clipboard ------
      copySource,

      copyDynamicIcon,

      icons: {
        mdiTrashCanOutline,
        mdiContentDuplicate,
        mdiCircleOutline,
        mdiPencilOutline,
        mdiContentCopy,
        mdiCheck,
        mdiChevronLeft,
        mdiChevronRight,
      },
      ACTIONS,
    }
  },
}
</script>
