<template>
  <div id="user-list">
    <job-tab-add-new-candidate-manually
      v-model="isAddCandidateSidebarActive"
      :questions="jobData.questions"
      :job-id="jobData.id"
      @refetch-data="
        fetchCandidates()
        fetchCandidatesOverview()
      "
    />

    <v-row class="mb-5">
      <v-col v-for="total in overview" :key="total.title" cols="12" md="4">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.count }}
              </h2>
              <span>{{ $t(total.title) }}</span>
            </div>

            <v-avatar
              :color="resolveCandidatesTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveCandidatesTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveCandidatesTotalIcon(total.title).color" class="rounded-0">
                {{ resolveCandidatesTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="stageFilter"
            :placeholder="$t('selectStatus')"
            :items="choices.stageChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-menu v-if="selected && selected.length" bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-t="'action'" color="primary" class="mb-4 me-3" dark v-bind="attrs" v-on="on" />
            </template>

            <v-list>
              <v-list-item v-if="canMoveToNextStepSelected">
                <v-dialog
                  v-model="moveToNextStepDialog"
                  max-width="850px"
                  persistent
                  overlay-color="#7f7f7f"
                  overlay-opacity="0.2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-t="'moveToNextStep'"
                      v-bind="attrs"
                      v-on="on"
                      @click="fetchEmailTemplatesData('in_progress')"
                    />
                  </template>
                  <v-card class="pa-sm-10 pa-3">
                    <v-card-title v-t="'moveToNextStep'" class="justify-center text-h5" />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center flex-column">
                          <div v-t="'confirmationModalUndoneQuestion'" />
                          <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                          <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            v-model="sendEmail"
                            :label="$t('notifyCandidateByEmail')"
                            hide-details="auto"
                            class="mt-0"
                            inset
                          />
                        </v-col>
                        <v-col v-if="sendEmail" cols="12">
                          <v-select
                            v-model="selectedEmail"
                            :label="$t('selectEmail')"
                            :placeholder="$t('selectEmail')"
                            :items="emailTemplates"
                            outlined
                            dense
                            hide-details="auto"
                            @change="setEmailTemplateData"
                          ></v-select>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="7">
                          <v-text-field
                            v-model="cc"
                            :label="$t('cc')"
                            :placeholder="$t('cc')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :hint="$t('emailSeparator')"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="5">
                          <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                            <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                      <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
                        <v-row>
                          <v-col cols="12" sm="7">
                            <v-text-field
                              v-model="selectedEmailTemplateSubject"
                              :label="$t('subject')"
                              :placeholder="$t('subject')"
                              outlined
                              dense
                              maxlength="100"
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-select
                              v-model="selectedEmailTemplateLang"
                              outlined
                              dense
                              :items="[
                                {
                                  text: $t('pl'),
                                  value: 'pl',
                                },
                                {
                                  text: $t('eng'),
                                  value: 'eng',
                                },
                              ]"
                              :label="$t('language')"
                              :placeholder="$t('language')"
                              hide-details="auto"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <summer-note
                              :config="{ height: 300 }"
                              hide-link-and-image-button
                              @ready="readySummerNote"
                            ></summer-note>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" class="d-flex justify-center mt-3">
                          <v-btn v-t="'discard'" outlined class="me-3" @click="closeMoveToNextStepDialog" />
                          <v-btn
                            color="primary"
                            :loading="moveToNextStepButtonLoading"
                            @click="moveSelectedCandidatesToNextStep"
                          >
                            {{ $t('moveToNextStep') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-list-item>
              <v-list-item v-if="canHireSelected">
                <v-dialog
                  v-model="hireDialog"
                  max-width="850px"
                  persistent
                  overlay-color="#7f7f7f"
                  overlay-opacity="0.2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-t="'hire'"
                      v-bind="attrs"
                      v-on="on"
                      @click="fetchEmailTemplatesData('hired')"
                    />
                  </template>
                  <v-card class="pa-sm-10 pa-3">
                    <v-card-title v-t="'hire'" class="justify-center text-h5" />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center flex-column">
                          <div v-t="'confirmationModalUndoneQuestion'" />
                          <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                          <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            v-model="sendEmail"
                            :label="$t('notifyCandidateByEmail')"
                            hide-details="auto"
                            class="mt-0"
                            inset
                          />
                        </v-col>
                        <v-col v-if="sendEmail" cols="12">
                          <v-select
                            v-model="selectedEmail"
                            :label="$t('selectEmail')"
                            :placeholder="$t('selectEmail')"
                            :items="emailTemplates"
                            outlined
                            dense
                            hide-details="auto"
                            @change="setEmailTemplateData"
                          ></v-select>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="7">
                          <v-text-field
                            v-model="cc"
                            :label="$t('cc')"
                            :placeholder="$t('cc')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :hint="$t('emailSeparator')"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="5">
                          <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                            <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                          </v-switch>
                        </v-col>
                        <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES) && sendEmail" cols="12">
                          <v-menu
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="workingStartDay"
                                :label="$t('workingStartDay')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="workingStartDay"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
                        <v-row>
                          <v-col cols="12" sm="7">
                            <v-text-field
                              v-model="selectedEmailTemplateSubject"
                              :label="$t('subject')"
                              :placeholder="$t('subject')"
                              outlined
                              dense
                              maxlength="100"
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-select
                              v-model="selectedEmailTemplateLang"
                              outlined
                              dense
                              :items="[
                                {
                                  text: $t('pl'),
                                  value: 'pl',
                                },
                                {
                                  text: $t('eng'),
                                  value: 'eng',
                                },
                              ]"
                              :label="$t('language')"
                              :placeholder="$t('language')"
                              hide-details="auto"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <summer-note
                              :config="{ height: 300 }"
                              hide-link-and-image-button
                              @ready="readySummerNote"
                            ></summer-note>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" class="d-flex justify-center mt-3">
                          <v-btn v-t="'discard'" outlined class="me-3" @click="closeHireDialog" />
                          <v-btn color="primary" :loading="hireButtonLoading" @click="hireSelected">{{
                            $t('hire')
                          }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-list-item>
              <v-list-item v-if="canRejectSelected">
                <v-dialog
                  v-model="rejectDialog"
                  max-width="850px"
                  persistent
                  overlay-color="#7f7f7f"
                  overlay-opacity="0.2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title
                      v-t="'reject'"
                      v-bind="attrs"
                      v-on="on"
                      @click="fetchEmailTemplatesData('rejected')"
                    />
                  </template>
                  <v-card class="pa-sm-10 pa-3">
                    <v-card-title v-t="'reject'" class="justify-center text-h5" />
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center flex-column">
                          <div v-t="'confirmationModalUndoneQuestion'" />
                          <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                          <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
                        </v-col>
                        <v-col cols="12">
                          <v-switch
                            v-model="sendEmail"
                            :label="$t('notifyCandidateByEmail')"
                            hide-details="auto"
                            class="mt-0"
                            inset
                          />
                        </v-col>
                        <v-col v-if="sendEmail" cols="12">
                          <v-select
                            v-model="selectedEmail"
                            :label="$t('selectEmail')"
                            :placeholder="$t('selectEmail')"
                            :items="emailTemplates"
                            outlined
                            dense
                            hide-details="auto"
                            @change="setEmailTemplateData"
                          ></v-select>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="7">
                          <v-text-field
                            v-model="cc"
                            :label="$t('cc')"
                            :placeholder="$t('cc')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :hint="$t('emailSeparator')"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12" sm="5">
                          <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                            <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                          </v-switch>
                        </v-col>
                        <v-col v-if="sendEmail" cols="12">
                          <v-select
                            v-model="selectedRejectReason"
                            :label="$t('selectRejectReason')"
                            :placeholder="$t('selectRejectReason')"
                            :items="rejectReasonChoices"
                            item-value="id"
                            item-text="rejectReason"
                            outlined
                            dense
                            hide-details="auto"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
                        <v-row>
                          <v-col cols="12" sm="7">
                            <v-text-field
                              v-model="selectedEmailTemplateSubject"
                              :label="$t('subject')"
                              :placeholder="$t('subject')"
                              outlined
                              dense
                              maxlength="100"
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-select
                              v-model="selectedEmailTemplateLang"
                              outlined
                              dense
                              :items="[
                                {
                                  text: $t('pl'),
                                  value: 'pl',
                                },
                                {
                                  text: $t('eng'),
                                  value: 'eng',
                                },
                              ]"
                              :label="$t('language')"
                              :placeholder="$t('language')"
                              hide-details="auto"
                            ></v-select>
                          </v-col>
                          <v-col cols="12">
                            <summer-note
                              :config="{ height: 300 }"
                              hide-link-and-image-button
                              @ready="readySummerNote"
                            ></summer-note>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col cols="12" class="d-flex justify-center mt-3">
                          <v-btn v-t="'discard'" outlined class="me-3" @click="closeRejectDialog" />
                          <v-btn color="primary" :loading="rejectButtonLoading" @click="rejectSelected">
                            {{ $t('reject') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="$can(ACTIONS.JOBS.MANAGE_CANDIDATES, jobData)"
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddCandidateSidebarActive = !isAddCandidateSidebarActive"
          >
            <v-icon class="me-2">{{ icons.mdiPlus }}</v-icon>
            <span v-t="'addCandidate'" />
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        v-model="selected"
        :headers="tableColumns"
        :show-select="$can(ACTIONS.JOBS.MANAGE_CANDIDATES, jobData)"
        :items="candidates"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalCandidatesListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
        }"
        @toggle-select-all="selectAllToggle"
      >
        <template #[`header.name`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.stage`]="{ header }">
          {{ $t(header.text, { postfix: '' }) }}
        </template>
        <template #[`header.step`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.gdprStatus`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template #[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            :ripple="false"
            :value="isSelected"
            :readonly="item.disabled"
            :disabled="item.disabled"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar v-if="item.assignedTo !== null" color="primary v-avatar-light-bg primary--text" size="32">
              <span class="font-weight-medium">{{
                avatarText(`${item.candidate.user.firstName} ${item.candidate.user.lastName}`)
              }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <component
                :is="$can(ACTIONS.READ, item) ? 'router-link' : 'span'"
                :to="{ name: 'apps-ats-candidate-view', params: { jobId: jobData.id, candidateId: item.candidate.id } }"
                class="font-weight-semibold text-truncate text-decoration-none"
                :class="$can(ACTIONS.READ, item) ? 'cursor-pointer' : ''"
              >
                {{ `${item.candidate.user.firstName} ${item.candidate.user.lastName}` }}
              </component>
              <span class="text-xs">{{ $t('updatedAt') }}: {{ formatDateToMonthShort(item.updatedAt) }}</span>
            </div>
          </div>
        </template>

        <template #[`item.step`]="{ item }">
          <span>{{ item.step.name }}</span>
        </template>

        <template #[`item.stage`]="{ item }">
          <v-chip
            small
            label
            :color="resolveCandidateStageColor(item.stage).color"
            :class="`v-chip-light-bg ${
              resolveCandidateStageColor(item.stage).color
            }--text font-weight-medium text-capitalize`"
          >
            {{ $t(resolveCandidateStageColor(item.stage).text) }}
          </v-chip>
        </template>

        <template #[`item.gdprStatus`]="{ item }">
          <v-chip
            small
            label
            :color="resolveGDPRStatusColor(item.gdprStatus).color"
            :class="`v-chip-light-bg ${
              resolveGDPRStatusColor(item.gdprStatus).color
            }--text font-weight-medium text-capitalize`"
          >
            {{ $t(resolveGDPRStatusColor(item.gdprStatus).text) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-row justify="center">
            <v-col cols="auto">
              <v-tooltip v-if="$can(ACTIONS.READ, item)" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    :to="{
                      name: 'apps-ats-candidate-view',
                      params: { jobId: jobData.id, candidateId: item.candidate.id },
                    }"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-t="'view'" />
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiPlus, mdiTrashCanOutline, mdiEyeOutline, mdiContentDuplicate } from '@mdi/js'
import { ref, computed, getCurrentInstance, nextTick, onMounted } from '@vue/composition-api'

import { avatarText, formatDateToMonthShort } from '@core/utils/filter'

import { useState, useActions } from 'vuex-composition-helpers'
import { invoke, until } from '@vueuse/core'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import useCandidateList from './useCandidateList'
import { EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE } from '@/plugins/summer-note/variables'
import JobTabAddNewCandidateManually from './JobTabAddNewCandidateManually.vue'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  components: {
    SummerNote,
    JobTabAddNewCandidateManually,
  },
  setup() {
    const isDialogVisible = ref(false)
    const isAddCandidateSidebarActive = ref(false)

    const { jobData } = useState('jobs', ['jobData'])
    const {
      getEmailTemplatesChoices,
      moveCandidatesToNextStep,
      hireCandidates,
      rejectCandidates,
      getRejectReasonsChoices,
    } = useActions('jobs', [
      'getEmailTemplatesChoices',
      'moveCandidatesToNextStep',
      'hireCandidates',
      'rejectCandidates',
      'getRejectReasonsChoices',
    ])

    const {
      fetchCandidates,
      fetchCandidatesOverview,
      resolveCandidateStageColor,
      resolveGDPRStatusColor,
      resolveCandidatesTotalIcon,
      delaySearchHandler,

      tableColumns,
      searchQuery,
      totalCandidatesListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      candidates,
      choices,
      selected,
      stageFilter,
    } = useCandidateList()

    onMounted(async () => {
      await fetchCandidatesOverview()
    })

    const selectAllToggle = (props) => {
      if (
        selected.value.length !==
        candidates.value.length - candidates.value.reduce((counted, candidate) => counted + !!candidate.disabled, 0)
      ) {
        selected.value = []
        props.items.forEach((item) => {
          if (!item.disabled) {
            selected.value.push(item)
          }
        })
      } else selected.value = []
    }

    const sendEmail = ref(true)
    const emailTemplates = ref([])
    const selectedEmail = ref(null)
    const selectedEmailTemplateHtml = ref('')
    const selectedEmailTemplateSubject = ref('')
    const selectedEmailTemplateLang = ref('')
    const cc = ref('')
    const sendCopyToRecruiter = ref(false)

    const vm = getCurrentInstance().proxy

    const editor = ref(null)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', selectedEmailTemplateHtml.value)
      editor.value.$on('change', (content) => {
        selectedEmailTemplateHtml.value = content
      })
    }

    const setEmailTemplateData = () => {
      const emailTemplate = emailTemplates.value.find((et) => et.value === selectedEmail.value)
      selectedEmailTemplateHtml.value = emailTemplate?.bodyHtml
      selectedEmailTemplateSubject.value = emailTemplate?.subject
      selectedEmailTemplateLang.value = emailTemplate?.lang
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const fetchEmailTemplatesData = async (actionType) => {
      emailTemplates.value = await getEmailTemplatesChoices({
        actionType,
      })
    }

    const resetEmailTemplateData = () => {
      sendEmail.value = true
      selectedEmail.value = null
      selectedEmailTemplateHtml.value = ''
      selectedEmailTemplateSubject.value = ''
      selectedEmailTemplateLang.value = ''
      cc.value = ''
      sendCopyToRecruiter.value = false
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const moveToNextStepDialog = ref(false)
    const moveToNextStepButtonLoading = ref(false)
    const closeMoveToNextStepDialog = () => {
      moveToNextStepDialog.value = false
      moveToNextStepButtonLoading.value = false
      selected.value = []
      resetEmailTemplateData()
    }
    const canMoveToNextStepSelected = computed(() => {
      if (selected.value.length === 0) {
        return false
      }

      return selected.value.every((candidate) => {
        return (
          candidate.step.id !== jobData.value.workflow.steps.slice(-1).pop().id &&
          (candidate.stage === 'in_progress' || candidate.stage === 'new') &&
          candidate.gdprStatus !== 'anonymized'
        )
      })
    })
    const moveSelectedCandidatesToNextStep = async () => {
      moveToNextStepButtonLoading.value = true
      await moveCandidatesToNextStep({
        jobId: jobData.value.id,
        candidatesOfferDetail: selected.value.map((candidate) => candidate.id),
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        sendEmail: sendEmail.value,
      })
      await fetchCandidates()
      await fetchCandidatesOverview()
      closeMoveToNextStepDialog()
    }

    const hireDialog = ref(false)
    const hireButtonLoading = ref(false)
    const workingStartDay = ref(new Date().toISOString().substr(0, 10))
    const closeHireDialog = () => {
      hireDialog.value = false
      hireButtonLoading.value = false
      selected.value = []
      resetEmailTemplateData()
    }
    const canHireSelected = computed(() => {
      if (selected.value.length === 0) {
        return false
      }

      return selected.value.every((candidate) => {
        return (
          candidate.step.id === jobData.value.workflow.steps.slice(-1).pop().id &&
          (candidate.stage === 'in_progress' || candidate.stage === 'new') &&
          candidate.gdprStatus !== 'anonymized'
        )
      })
    })
    const hireSelected = async () => {
      hireButtonLoading.value = true
      await hireCandidates({
        jobId: jobData.value.id,
        candidatesOfferDetail: selected.value.map((candidate) => candidate.id),
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        workingStartDay: workingStartDay.value,
        sendEmail: sendEmail.value,
      })
      await fetchCandidates()
      await fetchCandidatesOverview()
      closeHireDialog()
    }

    const rejectDialog = ref(false)
    const selectedRejectReason = ref(null)
    const rejectReasonChoices = ref([])

    invoke(async () => {
      await until(computed(() => rejectDialog.value)).toBe(true)
      nextTick(async () => {
        rejectReasonChoices.value = await getRejectReasonsChoices()
      })
    })

    const rejectButtonLoading = ref(false)
    const closeRejectDialog = () => {
      rejectDialog.value = false
      rejectButtonLoading.value = false
      selected.value = []
      resetEmailTemplateData()
    }
    const canRejectSelected = computed(() => {
      if (selected.value.length === 0) {
        return false
      }

      return selected.value.every((candidate) => {
        return (candidate.stage === 'in_progress' || candidate.stage === 'new') && candidate.gdprStatus !== 'anonymized'
      })
    })
    const rejectSelected = async () => {
      rejectButtonLoading.value = true
      await rejectCandidates({
        jobId: jobData.value.id,
        candidatesOfferDetail: selected.value.map((candidate) => candidate.id),
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        rejectReason: selectedRejectReason.value,
        sendEmail: sendEmail.value,
      })
      await fetchCandidates()
      await fetchCandidatesOverview()
      closeRejectDialog()
    }

    return {
      fetchCandidates,
      fetchCandidatesOverview,
      resolveCandidateStageColor,
      resolveGDPRStatusColor,
      resolveCandidatesTotalIcon,
      delaySearchHandler,
      avatarText,
      formatDateToMonthShort,

      selectAllToggle,

      sendEmail,
      emailTemplates,
      selectedEmail,
      selectedEmailTemplateHtml,
      selectedEmailTemplateSubject,
      selectedEmailTemplateLang,
      cc,
      sendCopyToRecruiter,

      setEmailTemplateData,
      fetchEmailTemplatesData,

      editor,
      emailVariablesSummernote,
      readySummerNote,

      moveToNextStepDialog,
      moveToNextStepButtonLoading,
      closeMoveToNextStepDialog,
      moveSelectedCandidatesToNextStep,
      canMoveToNextStepSelected,
      hireDialog,
      hireButtonLoading,
      workingStartDay,
      closeHireDialog,
      hireSelected,
      canHireSelected,
      rejectDialog,
      rejectButtonLoading,
      closeRejectDialog,
      rejectSelected,
      canRejectSelected,
      selectedRejectReason,
      rejectReasonChoices,

      isDialogVisible,
      tableColumns,
      searchQuery,
      totalCandidatesListTable,
      loading,
      options,
      userTotalLocal,
      isAddCandidateSidebarActive,
      overview,
      candidates,
      choices,
      selected,
      stageFilter,

      jobData,

      // icons
      icons: {
        mdiPlus,
        mdiTrashCanOutline,
        mdiEyeOutline,
        mdiContentDuplicate,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
