import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function workflowTemplateUtils() {
  const recruiterChoices = ref([])

  const { deleteWorkflowTemplate, duplicateWorkflowTemplate } = useActions('jobs', [
    'deleteWorkflowTemplate',
    'duplicateWorkflowTemplate',
  ])

  const { getRecruitersChoices } = useActions('company', ['getRecruitersChoices'])

  const recruiterText = (recruiterId) => {
    const recruiterData = recruiterChoices.value.find((recruiter) => recruiter.id === recruiterId)

    return `${recruiterData?.user?.firstName} ${recruiterData?.user?.lastName}`
  }

  const addStep = (steps) => {
    steps.push({
      name: '',
      stepDayLimit: null,
      description: '',
    })
  }

  const deleteStep = (steps, index) => {
    steps.splice(index, 1)
  }

  const fetchRecruitersChoices = async () => {
    recruiterChoices.value = await getRecruitersChoices()
  }

  return {
    deleteWorkflowTemplate,
    duplicateWorkflowTemplate,
    recruiterText,
    addStep,
    deleteStep,
    fetchRecruitersChoices,

    recruiterChoices,
  }
}
