<template>
  <v-card class="mb-7">
    <v-card-title v-t="'applyFormSettings'" />
    <v-card-text>
      <v-form @submit.prevent="handleApplyFormSettingsSubmit">
        <v-row>
          <v-col cols="12">
            <v-subheader v-t="'basicSettings'" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="localApplyFormSettings.showDescription"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('showJobDescription')"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="localApplyFormSettings.emailRequired"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('emailRequired')"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="localApplyFormSettings.phoneRequired"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('phoneNumberRequired')"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="localApplyFormSettings.preferredCityVisible"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('preferredCityVisible')"
            ></v-switch>
            <div v-if="localApplyFormSettings.preferredCityVisible">
              <v-switch
                v-model="localApplyFormSettings.preferredCityRequired"
                :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
                hide-details="auto"
                :label="$t('preferredCityRequired')"
              ></v-switch>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              v-model="localApplyFormSettings.attachmentsRequired"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('attachmentsRequired')"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="localApplyFormSettings.applyFormLang"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              :items="applyFormLanguageChoices"
              :label="$t('applyFormLanguage')"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-subheader v-t="'consents'" />
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="localApplyFormSettings.showTalentPoolConsent"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              hide-details="auto"
              class="mt-0"
              :label="$t('showTalentPoolConsent')"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="localApplyFormSettings.additionalConsents"
              :items="additionalConsentsChoices"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              :label="$t('additionalConsents')"
              :placeholder="$t('additionalConsents')"
              item-value="id"
              item-text="title"
              outlined
              dense
              multiple
              return-object
              hide-details="auto"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-subheader v-t="'emailTemplate'" />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="localApplyFormSettings.applyEmailTemplate"
              :readonly="!$can(ACTIONS.JOBS.MANAGE_JOB, jobData)"
              :items="emailTemplatesChoices"
              :label="$t('applyFormEmailTemplate')"
              :placeholder="$t('applyFormEmailTemplate')"
              outlined
              dense
              hide-details="auto"
            ></v-select>
          </v-col>

          <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, jobData)" cols="12" class="mt-3">
            <v-btn
              color="primary"
              type="submit"
              class="me-3"
              :loading="applyFormSettingsLoading"
              :disabled="!areApplyFormSettingsChanged"
              >{{ $t('save') }}</v-btn
            >
            <v-btn v-t="'discard'" outlined color="secondary" @click="restoreApplyFormSettings" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import _ from 'lodash'
import { emptyValueFormatter } from '@core/utils/filter'
import jobUtils from '@/utils/jobsUtils'
import { ACTIONS } from '@/plugins/acl/const'

export default {
  setup() {
    const {
      fetchAdditionalConsentsChoices,
      fetchEmailTemplatesChoices,

      applyFormLanguageChoices,
      additionalConsentsChoices,
      emailTemplatesChoices,
    } = jobUtils()

    const { updatedJobPart } = useActions('jobs', ['updatedJobPart'])
    const { jobData } = useState('jobs', ['jobData'])

    const localApplyFormSettings = ref([])

    const applyFormSettingsLoading = ref(false)

    localApplyFormSettings.value = _.cloneDeep({
      additionalConsents: jobData.value.additionalConsents,
      applyEmailTemplate: jobData.value.applyEmailTemplate,
      applyFormLang: jobData.value.applyFormLang,
      attachmentsRequired: jobData.value.attachmentsRequired,
      emailRequired: jobData.value.emailRequired,
      phoneRequired: jobData.value.phoneRequired,
      preferredCityRequired: jobData.value.preferredCityRequired,
      preferredCityVisible: jobData.value.preferredCityVisible,
      showDescription: jobData.value.showDescription,
      showTalentPoolConsent: jobData.value.showTalentPoolConsent,
    })

    const areApplyFormSettingsChanged = computed(() => {
      return (
        localApplyFormSettings.value.applyEmailTemplate !== jobData.value.applyEmailTemplate ||
        localApplyFormSettings.value.applyFormLang !== jobData.value.applyFormLang ||
        localApplyFormSettings.value.attachmentsRequired !== jobData.value.attachmentsRequired ||
        localApplyFormSettings.value.emailRequired !== jobData.value.emailRequired ||
        localApplyFormSettings.value.phoneRequired !== jobData.value.phoneRequired ||
        localApplyFormSettings.value.preferredCityRequired !== jobData.value.preferredCityRequired ||
        localApplyFormSettings.value.preferredCityVisible !== jobData.value.preferredCityVisible ||
        localApplyFormSettings.value.showDescription !== jobData.value.showDescription ||
        localApplyFormSettings.value.showTalentPoolConsent !== jobData.value.showTalentPoolConsent ||
        !_.isEmpty(
          _.differenceWith(
            localApplyFormSettings.value.additionalConsents,
            jobData.value.additionalConsents.map((additionalConsent) => additionalConsent.id),
            _.isEqual,
          ),
        ) ||
        !_.isEmpty(
          _.differenceWith(
            jobData.value.additionalConsents.map((additionalConsent) => additionalConsent.id),
            localApplyFormSettings.value.additionalConsents,
            _.isEqual,
          ),
        )
      )
    })

    const handleApplyFormSettingsSubmit = async () => {
      if (!areApplyFormSettingsChanged) return

      applyFormSettingsLoading.value = true

      const { success } = await updatedJobPart({
        jobId: jobData.value.id,
        payload: {
          ...localApplyFormSettings.value,
        },
      })
      if (success) {
        localApplyFormSettings.value = _.cloneDeep({
          additionalConsents: jobData.value.additionalConsents,
          applyEmailTemplate: jobData.value.applyEmailTemplate,
          applyFormLang: jobData.value.applyFormLang,
          attachmentsRequired: jobData.value.attachmentsRequired,
          emailRequired: jobData.value.emailRequired,
          phoneRequired: jobData.value.phoneRequired,
          preferredCityRequired: jobData.value.preferredCityRequired,
          preferredCityVisible: jobData.value.preferredCityVisible,
          showDescription: jobData.value.showDescription,
          showTalentPoolConsent: jobData.value.showTalentPoolConsent,
        })
      }

      applyFormSettingsLoading.value = false
    }

    const restoreApplyFormSettings = () => {
      localApplyFormSettings.value = _.cloneDeep({
        additionalConsents: jobData.value.additionalConsents,
        applyEmailTemplate: jobData.value.applyEmailTemplate,
        applyFormLang: jobData.value.applyFormLang,
        attachmentsRequired: jobData.value.attachmentsRequired,
        emailRequired: jobData.value.emailRequired,
        phoneRequired: jobData.value.phoneRequired,
        preferredCityRequired: jobData.value.preferredCityRequired,
        preferredCityVisible: jobData.value.preferredCityVisible,
        showDescription: jobData.value.showDescription,
        showTalentPoolConsent: jobData.value.showTalentPoolConsent,
      })
    }

    onMounted(async () => {
      await fetchAdditionalConsentsChoices()
      await fetchEmailTemplatesChoices()
    })

    return {
      handleApplyFormSettingsSubmit,
      restoreApplyFormSettings,
      emptyValueFormatter,

      areApplyFormSettingsChanged,

      jobData,

      localApplyFormSettings,
      applyFormLanguageChoices,
      additionalConsentsChoices,
      emailTemplatesChoices,
      applyFormSettingsLoading,

      ACTIONS,
    }
  },
}
</script>
