export default function useDraggableOptions() {
  const dragOptions = {
    animation: 400,
    ghostClass: 'ghost',
    swapThreshold: 0.7,
    invertedSwapThreshold: 0.7,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    handle: '.my-handle',
  }

  const onDragStart = (e) => {
    e.target.classList.add('grabbing')
  }

  const onDragEnd = (e) => {
    e.target.classList.remove('grabbing')
  }

  return {
    dragOptions,
    onDragStart,
    onDragEnd,
  }
}
