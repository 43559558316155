import { getCurrentInstance, ref } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

export default function jobUtils() {
  const vm = getCurrentInstance().proxy
  const applyFormLanguageChoices = [
    {
      text: vm.$t('polish'),
      value: 'pl',
    },
    {
      text: vm.$t('english'),
      value: 'eng',
    },
    {
      text: vm.$t('russian'),
      value: 'ru',
    },
    {
      text: vm.$t('spanish'),
      value: 'es',
    },
  ]

  const recruiterChoices = ref([])
  const sourcesChoices = ref([])
  const additionalConsentsChoices = ref([])
  const emailTemplatesChoices = ref([])

  const { getRecruitersChoices, getAdditionalConsents } = useActions('company', [
    'getRecruitersChoices',
    'getAdditionalConsents',
  ])
  const { getApplyFormSourcesChoices, getEmailTemplatesChoices, getAllWorkflowTemplatesDetails } = useActions('jobs', [
    'getApplyFormSourcesChoices',
    'getEmailTemplatesChoices',
    'getAllWorkflowTemplatesDetails',
  ])
  const { jobData } = useState('jobs', ['jobData'])

  const addQuestion = (questions) => {
    questions.push({
      question: '',
      required: true,
    })
  }

  const deleteQuestion = (questions, index) => {
    questions.splice(index, 1)
  }

  const recruiterText = (recruiterId) => {
    const recruiterData = recruiterChoices.value.find((recruiter) => recruiter.id === recruiterId)

    return `${recruiterData?.user?.firstName} ${recruiterData?.user?.lastName}`
  }

  const fetchRecruitersChoices = async () => {
    recruiterChoices.value = await getRecruitersChoices()
  }

  const fetchSourcesChoices = async () => {
    sourcesChoices.value = await getApplyFormSourcesChoices()
  }

  const fetchAdditionalConsentsChoices = async () => {
    additionalConsentsChoices.value = await getAdditionalConsents()
  }

  const fetchEmailTemplatesChoices = async () => {
    emailTemplatesChoices.value = await getEmailTemplatesChoices({
      actionType: 'applied',
    })
  }

  const mappedWorkflowTemplatesChoices = ref([])

  const fetchWorkflowTemplates = async () => {
    mappedWorkflowTemplatesChoices.value = []

    mappedWorkflowTemplatesChoices.value.push(
      {
        header: 'Selected',
      },
      jobData.value.workflow,
    )
    const workflowTemplates = await getAllWorkflowTemplatesDetails()
    workflowTemplates.forEach((el) => {
      delete Object.assign(el, { steps: el.stepsTemplate }).stepsTemplate
    })
    mappedWorkflowTemplatesChoices.value.push(
      {
        divider: true,
      },
      { header: 'Templates' },
      ...workflowTemplates,
    )
  }

  return {
    addQuestion,
    deleteQuestion,
    recruiterText,
    fetchRecruitersChoices,
    fetchSourcesChoices,
    fetchAdditionalConsentsChoices,
    fetchEmailTemplatesChoices,
    fetchWorkflowTemplates,

    applyFormLanguageChoices,

    recruiterChoices,
    sourcesChoices,
    additionalConsentsChoices,
    emailTemplatesChoices,
    mappedWorkflowTemplatesChoices,
  }
}
