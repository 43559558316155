<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            jobName: jobData.title,
          }"
        />
        <job-bio-panel :loading="loading" @refetch-candidates="refetchCandidates" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="selectedTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-if="!loading" id="user-tabs-content" v-model="selectedTab" class="mt-5 pa-3" touchless>
          <v-tab-item v-if="jobData.stage !== 'draft'">
            <job-tab-candidate-list v-if="jobData.id" ref="jobCandidatesListRef" />
          </v-tab-item>
          <v-tab-item>
            <job-tab-apply-form />
          </v-tab-item>
          <v-tab-item>
            <job-tab-workflow />
          </v-tab-item>
          <v-tab-item>
            <job-tab-questions />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick, watch, getCurrentInstance } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { mdiAccountCancelOutline, mdiAccountOutline, mdiAbacus, mdiAlphaEBoxOutline } from '@mdi/js'
import { subject } from '@casl/ability'
import { useRouter } from '@core/utils'
import router from '@/router'

import JobBioPanel from './job-bio-panel/JobBioPanel.vue'
import JobTabApplyForm from './job-tab-apply-form/JobTabApplyForm.vue'
import JobTabCandidateList from './job-tab-candidates/JobTabCandidateList.vue'
import JobTabQuestions from './job-tab-questions/JobTabQuestions.vue'
import JobTabWorkflow from './job-tab-workflow/JobTabWorkflow.vue'
import { PRIVATE, ACTIONS } from '@/plugins/acl/const'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    JobBioPanel,
    JobTabApplyForm,
    JobTabCandidateList,
    JobTabQuestions,
    JobTabWorkflow,
  },
  setup() {
    const { getJobDetail } = useActions('jobs', ['getJobDetail'])
    const { jobData } = useState('jobs', ['jobData'])
    const { SET_JOB_DATA } = useMutations('jobs', ['SET_JOB_DATA'])

    const vm = getCurrentInstance().proxy
    const tabs = ref([])
    const selectedTab = ref(null)
    const jobCandidatesListRef = ref(null)
    const loading = ref(false)

    const fetchJobData = async () => {
      await getJobDetail(router.currentRoute.params.id)

      if (!vm.$can(ACTIONS.READ, jobData.value)) {
        vm.$router.push({ name: 'misc-not-authorized' })
      } else {
        nextTick(() => {
          if (jobData.value.stage !== 'draft') {
            tabs.value = [
              { icon: mdiAccountCancelOutline, title: 'candidates' },
              { icon: mdiAccountOutline, title: 'applyFormSettings' },
              { icon: mdiAlphaEBoxOutline, title: 'workflow' },
              { icon: mdiAbacus, title: 'questions' },
            ]
          } else {
            tabs.value = [
              { icon: mdiAccountOutline, title: 'applyFormSettings' },
              { icon: mdiAlphaEBoxOutline, title: 'workflow' },
              { icon: mdiAbacus, title: 'questions' },
            ]
          }
        })
      }
    }

    const refetchCandidates = async () => {
      await jobCandidatesListRef.value?.fetchCandidates()
      await jobCandidatesListRef.value?.fetchCandidatesOverview()
    }

    const { route } = useRouter()

    // INFO: dirty hack to handle dynamic tabs (job stage draft -> ongoing)
    watch(
      () => jobData.value.stage,
      (newValue, oldValue) => {
        if (newValue === 'ongoing' && oldValue === 'draft') {
          tabs.value = [
            { icon: mdiAccountCancelOutline, title: 'candidates' },
            { icon: mdiAccountOutline, title: 'applyFormSettings' },
            { icon: mdiAlphaEBoxOutline, title: 'workflow' },
            { icon: mdiAbacus, title: 'questions' },
          ]
          selectedTab.value = 3
        }
      },
    )

    watch(
      () => route.value.params.id,
      async () => {
        await fetchJobData()
        await refetchCandidates()
      },
    )

    onMounted(async () => {
      await fetchJobData()
    })

    onUnmounted(() => {
      SET_JOB_DATA(
        subject(PRIVATE.ATS.JOB, {
          workflow: {},
        }),
      )
    })

    return {
      jobCandidatesListRef,
      refetchCandidates,
      fetchJobData,
      tabs,
      selectedTab,
      jobData,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
