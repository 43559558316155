<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span class="font-weight-semibold text-base text--primary">Add new candidate</span>
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="candidateData.user.firstName"
              outlined
              dense
              :rules="[validators.required]"
              :label="$t('firstName', { postfix: '*' })"
              :placeholder="$t('firstName', { postfix: '*' })"
              hide-details="auto"
              maxlength="150"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="candidateData.user.lastName"
              outlined
              dense
              :rules="[validators.required]"
              :label="$t('lastName', { postfix: '*' })"
              :placeholder="$t('lastName', { postfix: '*' })"
              hide-details="auto"
              maxlength="150"
            />
          </v-col>
          <v-col cols="12">
            <v-alert color="info" text class="mb-0">
              <p v-t="'addCandidateManuallyAlert'" class="font-weight-semibold mb-1" />
            </v-alert>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="candidateData.companyJobGdpr.accepted"
              desnse
              :label="`${company.gdpr}*`"
              :rules="[validators.required]"
              :disabled="!company.gdpr"
              hide-details="auto"
              class="mt-0"
            ></v-checkbox>
          </v-col>
          <v-col cols="12"
            ><v-checkbox
              v-model="candidateData.contactViaEmail.accepted"
              :label="`${permitToContactViaEmailText}*`"
              :rules="[validators.required]"
              :disabled="!company.registrationName"
              hide-details="auto"
              class="mt-0"
            ></v-checkbox
          ></v-col>
          <v-col v-for="additionalConsent in job.additionalConsents" :key="additionalConsent.id" cols="12">
            <v-checkbox
              v-model="additionalConsent.isAccepted"
              :rules="additionalConsent.required ? [validators.required] : []"
              hide-details="auto"
              class="mt-0"
            >
              <template v-slot:label>
                {{ additionalConsent.consent }} {{ additionalConsent.required ? '*' : '' }}
              </template>
              ></v-checkbox
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels class="mb-3" multiple>
              <v-expansion-panel v-for="(expansionPanel, index) in expansionPanels" :key="index">
                <v-expansion-panel-header> {{ $t(expansionPanel.title) }} </v-expansion-panel-header>
                <v-expansion-panel-content v-if="index === 0">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="candidateData.user.email"
                        outlined
                        dense
                        type="email"
                        :rules="[validators.emailValidator]"
                        :label="$t('email', { postfix: '' })"
                        :placeholder="$t('email', { postfix: '' })"
                        hide-details="auto"
                        maxlength="254"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="candidateData.user.phone"
                        outlined
                        dense
                        :label="$t('phoneNumber')"
                        :placeholder="$t('phoneNumber')"
                        hide-details="auto"
                        maxlength="31"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="candidateData.preferredCity"
                        outlined
                        dense
                        :label="$t('preferredCity')"
                        :placeholder="$t('preferredCity')"
                        hide-details="auto"
                        maxlength="64"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="candidateData.applyFormSource"
                        :items="sourcesChoices"
                        label="Select source"
                        item-value="id"
                        item-text="source"
                        outlined
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else-if="index === 1">
                  <v-row>
                    <v-col v-for="(file, fileIndex) in candidateData.files" :key="`File--${fileIndex}`" cols="12">
                      <v-card outlined>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="pb-0">
                              <file-pond
                                v-model="file.file"
                                :label-idle="$t('filePondIdle')"
                                :allow-multiple="false"
                                accepted-file-types="application/pdf, image/png, image/jpeg"
                                max-file-size="20MB"
                                max-total-file-size="20MB"
                                @addfile="setUploadedFileName(file)"
                              />
                            </v-col>
                            <v-col cols="10" class="pt-0">
                              <v-text-field
                                v-model="file.name"
                                outlined
                                dense
                                :rules="[validators.required]"
                                :label="$t('title')"
                                :placeholder="$t('title')"
                                hide-details="auto"
                                maxlength="150"
                              />
                            </v-col>
                            <v-col cols="2" class="pt-0">
                              <v-tooltip top transition="scroll-y-transition" open-delay="150" color="secondary">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    elevation="0"
                                    color="error"
                                    icon
                                    v-bind="attrs"
                                    @click="removeFileToUpload(fileIndex)"
                                    v-on="on"
                                  >
                                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                                  </v-btn>
                                </template>
                                <span class="text-xs"> {{ $t('removeFile') }} </span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-btn color="primary" block @click="addFileToUpload">{{ $t('addFile') }}</v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else-if="index === 2">
                  <v-row>
                    <v-col v-for="customField in customFields" :key="customField.id" cols="12">
                      <v-text-field
                        v-model="customField.field"
                        outlined
                        dense
                        :label="customField.name"
                        :placeholder="customField.name"
                        hide-details
                        maxlength="100"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else-if="index === 3">
                  <v-row>
                    <v-col v-for="(question, questionIndex) in questions" :key="`Question--${questionIndex}`" cols="12">
                      <h3>{{ question.question }}</h3>
                      <v-textarea
                        auto-grow
                        outlined
                        dense
                        rows="5"
                        maxlength="150"
                        @input="applyAnswer($event, question)"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading"> Add </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetJobData" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required, emailValidator } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline, mdiDrag } from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import fileUploadUtils from '@/utils/fileUploadUtils'
import jobUtils from '@/utils/jobsUtils'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  components: {
    FilePond,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const { setUploadedFileName } = fileUploadUtils()
    const {
      fetchAdditionalConsentsChoices,
      fetchSourcesChoices,

      applyFormLanguageChoices,
      additionalConsentsChoices,
      sourcesChoices,
      emailTemplatesChoices,
    } = jobUtils()

    const expansionPanels = computed(() => {
      if (props.questions.length > 0)
        return [
          {
            title: 'extraData',
          },
          {
            title: 'files',
          },
          {
            title: 'customFields',
          },
          { title: 'questions' },
        ]

      return [
        {
          title: 'extraData',
        },
        {
          title: 'files',
        },
        {
          title: 'customFields',
        },
      ]
    })

    const blankCandidateData = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      preferredCity: '',
      files: [],
      answers: [],
      applyFormSource: null,
      companyJobGdpr: {
        accepted: false,
        consent: '',
        wasRequired: true,
      },
      contactViaEmail: {
        accepted: false,
        consent: '',
        wasRequired: true,
      },
      additionalConsents: [],
    }

    const customFields = ref([])

    const { manualApplyToJob, getManualApplyData } = useActions('jobs', ['manualApplyToJob', 'getManualApplyData'])
    const { getCandidateCustomFields } = useActions('company', ['getCandidateCustomFields'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const company = ref({})
    const job = ref({})

    const candidateData = ref(_.cloneDeep(blankCandidateData))

    const assignedToItems = ref([])

    const mappedWorkflowTemplatesChoices = ref([])

    const addFileToUpload = () => {
      candidateData.value.files.push({
        file: null,
        name: '',
      })
    }

    const removeFileToUpload = (fileIndex) => {
      candidateData.value.files.splice(fileIndex, 1)
    }

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetJobData = () => {
      resetForm()
      candidateData.value = _.cloneDeep(blankCandidateData)
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true

        const payload = {
          ...candidateData.value,
          files: candidateData.value.files.map((file) => {
            return {
              file: file.file[0].getFileEncodeBase64String(),
              name: file.name,
            }
          }),
          offer: props.jobId,
          additionalConsents: candidateData.value.additionalConsents.map((additionalConsent) => ({
            consent: additionalConsent.consent,
            accept: additionalConsent.isAccepted,
          })),
          customFields: customFields.value.map((field) => {
            return { customField: field.id, field: field.field }
          }),
        }

        await manualApplyToJob({
          jobId: props.jobId,
          payload,
        })
        loading.value = false
        resetJobData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    const permitToContactViaEmailText = computed(() => {
      if (root.$i18n.locale === 'en')
        return `I hereby agree that LightApply Sp. z o.o. may contact me electronically in order to provide information on the recruitment process for position ${job.value.title} in company ${company.value.registrationName}.`

      return `Wyrażam zgodę na kontaktowanie się ze mną drogą elektroniczną przez LightApply Sp. z o.o. w celu przekazania informacji dotyczących procesu rekrutacji na stanowisko ${job.value.title} w firmie ${company.value.registrationName}.`
    })

    const applyAnswer = (event, q) => {
      const answerIndex = candidateData.value.answers.findIndex((a) => a.question === q.id)
      if (answerIndex === -1) {
        candidateData.value.answers.push({
          question: q.id,
          answer: event,
        })
      } else {
        candidateData.value.answers[answerIndex].answer = event
      }
    }

    watch(
      () => props.isAddNewUserSidebarActive,
      async () => {
        const { data } = await getManualApplyData(props.jobId)
        company.value = data.company
        job.value = data.job

        candidateData.value.companyJobGdpr.consent = company.value.gdpr
        candidateData.value.contactViaEmail.consent = permitToContactViaEmailText.value

        customFields.value = await getCandidateCustomFields()
        await fetchAdditionalConsentsChoices()
        await fetchSourcesChoices()
      },
    )

    return {
      setUploadedFileName,
      resetJobData,
      onSubmit,
      applyAnswer,

      addFileToUpload,
      removeFileToUpload,

      expansionPanels,

      permitToContactViaEmailText,

      applyFormLanguageChoices,
      additionalConsentsChoices,
      sourcesChoices,
      emailTemplatesChoices,
      mappedWorkflowTemplatesChoices,

      customFields,
      form,
      loading,
      candidateData,
      valid,
      assignedToItems,
      company,
      job,

      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
        mdiDrag,
      },
    }
  },
}
</script>
