<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title>Workflow</v-card-title>
      <v-card-subtitle class="mt-1">
        <v-alert v-if="canEditWorkflow" color="warning" text>
          <p v-t="'anyChangeHereWillNotAffectTheTemplate'" class="font-weight-semibold mb-1" />
        </v-alert>
      </v-card-subtitle>
      <v-card-text>
        <v-autocomplete
          v-model="localWorkflow"
          :items="mappedWorkflowTemplatesChoices"
          :label="$t('selectWorkflow')"
          :placeholder="$t('selectWorkflow')"
          :readonly="!workflowEdit"
          item-text="title"
          item-value="id"
          hide-details="auto"
          outlined
          dense
          return-object
          @change="updateStepsFrozenOrder"
        />
        <div v-if="localWorkflow.internalNote" class="text-sm my-2">{{ localWorkflow.internalNote }}</div>
        <v-form v-model="stepsValid" @submit.prevent="handleWorkflowSubmit">
          <v-row class="mt-2">
            <v-col cols="12">
              <draggable
                v-model="localWorkflow.steps"
                v-bind="dragOptions"
                :scroll-sensitivity="350"
                :force-fallback="true"
                :disabled="!workflowEdit"
                @end="onDragEnd"
                @start="onDragStart"
              >
                <div v-for="(step, index) in localWorkflow.steps" :key="`Step--${index}`" class="py-3">
                  <v-card outlined class="card--border-primary my-handle">
                    <v-card-title v-if="!workflowEdit">
                      <v-row>
                        <v-col cols="8" sm="10">
                          {{ step.name }}
                        </v-col>
                        <v-col cols="4" sm="2" class="text-sm">
                          {{ $t('stepDayLimit', { postfix: ':' }) }} {{ emptyValueFormatter(step.stepDayLimit) }}
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle v-if="!workflowEdit"> {{ step.description }} </v-card-subtitle>
                    <v-card-title v-else>
                      <v-row>
                        <v-col cols="12" md="8">
                          <v-text-field
                            v-model="step.name"
                            :label="$t('name')"
                            :placeholder="$t('name')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :rules="[validators.required]"
                          >
                            <template #prepend>
                              <v-icon class="cursor-pointer" color="primary">{{ icons.mdiDrag }}</v-icon>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="step.stepDayLimit"
                            label="Day limit"
                            outlined
                            dense
                            type="number"
                            min="0"
                            max="365"
                            hide-details="auto"
                            :rules="[validators.minPositiveValue, validators.maxDayValueWithEmptyValue]"
                          >
                            <template #append-outer>
                              <v-tooltip top transition="scroll-y-transition" open-delay="150">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="error"
                                    x-small
                                    v-bind="attrs"
                                    @click="deleteStep(localWorkflow.steps, index)"
                                    v-on="on"
                                  >
                                    <v-icon class="me-2" size="16">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                    <span v-t="'delete'" />
                                  </v-btn>
                                </template>
                                <span class="text-xs"> Delete step </span>
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="step.description"
                            outlined
                            auto-grow
                            label="description"
                            rows="2"
                            hide-details="auto"
                            maxlength="1000"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </div>
              </draggable>
            </v-col>
            <v-col v-if="workflowEdit" cols="12">
              <v-btn color="primary" block @click="addStep(localWorkflow.steps)">Add step</v-btn>
            </v-col>
            <v-col v-if="canEditWorkflow" cols="12">
              <v-btn v-if="!workflowEdit" color="primary" @click="workflowEdit = !workflowEdit">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
              <v-btn
                v-if="workflowEdit"
                color="primary"
                type="submit"
                class="me-3"
                :loading="workflowLoading"
                :disabled="
                  !stepsValid || !(areStepsChanged || areStepsOrderChanged) || localWorkflow.steps.length === 0
                "
                >{{ $t('save') }}</v-btn
              >
              <v-btn v-if="workflowEdit" v-t="'discard'" outlined color="secondary" @click="restoreWorkflow" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useState, useActions } from 'vuex-composition-helpers'
import { mdiTrashCanOutline, mdiDrag, mdiPencilOutline } from '@mdi/js'
import _ from 'lodash'
import draggable from 'vuedraggable'

import { required, minPositiveValue, maxDayValueWithEmptyValue } from '@core/utils/validation'
import { emptyValueFormatter } from '@core/utils/filter'
import useDraggableOptions from '@/plugins/draggable'
import workflowTemplateUtils from '@/utils/workflowTemplateUtils'
import jobUtils from '@/utils/jobsUtils'

export default {
  components: {
    draggable,
  },
  setup() {
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const { addStep, deleteStep } = workflowTemplateUtils()
    const { fetchWorkflowTemplates, mappedWorkflowTemplatesChoices } = jobUtils()

    const { jobData } = useState('jobs', ['jobData'])
    const { updatedJobPart } = useActions('jobs', ['updatedJobPart'])

    const localWorkflow = ref([])
    const frozenWorkflow = ref([])
    const workflowLoading = ref(false)
    const stepsValid = ref(true)
    const workflowEdit = ref(false)
    const frozenStepsOrder = ref([])

    localWorkflow.value = _.cloneDeep(jobData.value.workflow)
    frozenWorkflow.value = Object.freeze(_.cloneDeep(jobData.value.workflow))

    frozenStepsOrder.value = Object.freeze(
      localWorkflow.value.steps.filter((step) => Object.hasOwnProperty.bind(step)('order')).map((step) => step.order),
    )

    const areStepsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(localWorkflow.value.steps, jobData.value.workflow.steps, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(jobData.value.workflow.steps, localWorkflow.value.steps, _.isEqual))
      )
    })

    const areStepsOrderChanged = computed(() => {
      return !_.isEqual(
        localWorkflow.value.steps.filter((step) => Object.hasOwnProperty.bind(step)('order')).map((step) => step.order),
        frozenStepsOrder.value,
      )
    })

    const canEditWorkflow = computed(() => {
      return jobData.value.stage === 'draft'
    })

    const handleWorkflowSubmit = async () => {
      if (!canEditWorkflow) return

      if (!areStepsChanged) return

      if (!stepsValid.value) return

      workflowLoading.value = true

      localWorkflow.value.steps.forEach((step, index) => {
        if (index in frozenStepsOrder.value) {
          // eslint-disable-next-line no-param-reassign
          step.order = frozenStepsOrder.value[index]
        } else {
          // eslint-disable-next-line no-param-reassign
          delete step.order
        }
        if (step.stepDayLimit === '') {
          // eslint-disable-next-line no-param-reassign
          step.stepDayLimit = null
        }
      })

      await updatedJobPart({
        jobId: jobData.value.id,
        payload: { workflow: localWorkflow.value },
      })

      localWorkflow.value = _.cloneDeep(jobData.value.workflow)
      frozenWorkflow.value = Object.freeze(_.cloneDeep(jobData.value.workflow))

      frozenStepsOrder.value = Object.freeze(
        localWorkflow.value.steps.filter((step) => Object.hasOwnProperty.bind(step)('order')).map((step) => step.order),
      )

      await fetchWorkflowTemplates()

      workflowLoading.value = false
      workflowEdit.value = false
    }

    const restoreWorkflow = () => {
      localWorkflow.value = _.cloneDeep(frozenWorkflow.value)
      workflowEdit.value = false
    }

    const updateStepsFrozenOrder = (selectedWorkflow) => {
      frozenStepsOrder.value = Object.freeze(
        selectedWorkflow.steps.filter((step) => Object.hasOwnProperty.bind(step)('order')).map((step) => step.order),
      )
    }

    onMounted(async () => {
      await fetchWorkflowTemplates()
    })

    return {
      handleWorkflowSubmit,

      updateStepsFrozenOrder,

      restoreWorkflow,
      addStep,
      deleteStep,
      onDragStart,
      onDragEnd,
      emptyValueFormatter,

      mappedWorkflowTemplatesChoices,

      areStepsOrderChanged,
      canEditWorkflow,

      jobData,

      areStepsChanged,

      dragOptions,
      localWorkflow,
      workflowLoading,
      stepsValid,
      workflowEdit,

      validators: {
        required,
        minPositiveValue,
        maxDayValueWithEmptyValue,
      },

      icons: {
        mdiTrashCanOutline,
        mdiDrag,
        mdiPencilOutline,
      },
    }
  },
}
</script>
