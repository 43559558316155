<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-text>
        <v-form v-model="questionsValid" @submit.prevent="handleQuestionsSubmit">
          <v-row>
            <v-col cols="12">
              <draggable
                v-model="localQuestions"
                v-bind="dragOptions"
                :scroll-sensitivity="350"
                :force-fallback="true"
                :disabled="!questionsEdit"
                @end="onDragEnd"
                @start="onDragStart"
              >
                <div v-for="(questionData, index) in localQuestions" :key="`Question--${index}`" class="py-3">
                  <v-card outlined class="card--border-primary">
                    <v-card-title v-if="!questionsEdit">
                      {{ questionData.question }}{{ questionData.required ? '*' : '' }}
                    </v-card-title>
                    <v-card-subtitle v-if="!questionsEdit">
                      <v-chip
                        v-t="`${questionData.required ? 'required' : 'notRequired'}`"
                        x-small
                        label
                        color="info"
                        outlined
                        class="font-weight-semibold"
                      />
                    </v-card-subtitle>
                    <v-card-title v-if="questionsEdit">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="questionData.question"
                            :label="$t('question')"
                            :placeholder="$t('question')"
                            outlined
                            dense
                            maxlength="100"
                            hide-details="auto"
                            :rules="[validators.required]"
                          >
                            <template #prepend>
                              <v-icon class="cursor-pointer my-handle" color="primary">{{ icons.mdiDrag }}</v-icon>
                            </template>
                            <template #append-outer>
                              <v-tooltip top transition="scroll-y-transition" open-delay="150">
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="error"
                                    x-small
                                    v-bind="attrs"
                                    @click="deleteQuestion(localQuestions, index)"
                                    v-on="on"
                                  >
                                    <v-icon class="me-2" size="16">
                                      {{ icons.mdiTrashCanOutline }}
                                    </v-icon>
                                    <span v-t="'delete'" />
                                  </v-btn>
                                </template>
                                <span v-t="'delete'" class="text-xs" />
                              </v-tooltip>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-checkbox
                            v-model="questionData.required"
                            class="mt-0 pt-0 d-inline-flex"
                            hide-details
                            :label="$t('required')"
                          />
                        </v-col>
                      </v-row>
                    </v-card-title>
                  </v-card>
                </div>
              </draggable>
            </v-col>
            <v-col v-if="questionsEdit" cols="12">
              <v-btn v-t="'addQuestion'" color="primary" block @click="addQuestion(localQuestions)" />
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="$can(ACTIONS.JOBS.MANAGE_JOB, jobData) && !questionsEdit"
                color="primary"
                @click="questionsEdit = !questionsEdit"
              >
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
              <v-btn
                v-if="questionsEdit"
                color="primary"
                type="submit"
                class="me-3"
                :loading="questionsLoading"
                :disabled="!questionsValid || !(areQuestionsChanged || areQuestionsOrderChanged)"
                >{{ $t('save') }}</v-btn
              >
              <v-btn v-if="questionsEdit" v-t="'discard'" outlined color="secondary" @click="restoreQuestions" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { mdiTrashCanOutline, mdiDrag, mdiPencilOutline } from '@mdi/js'
import _ from 'lodash'
import draggable from 'vuedraggable'

import { required, minPositiveValue, maxDayValueWithEmptyValue } from '@core/utils/validation'
import { emptyValueFormatter } from '@core/utils/filter'
import useDraggableOptions from '@/plugins/draggable'
import jobUtils from '@/utils/jobsUtils'
import { ACTIONS } from '@/plugins/acl/const'

export default {
  components: {
    draggable,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { dragOptions, onDragStart, onDragEnd } = useDraggableOptions()

    const { addQuestion, deleteQuestion } = jobUtils()

    const { updatedJobPart } = useActions('jobs', ['updatedJobPart'])
    const { jobData } = useState('jobs', ['jobData'])

    const localQuestions = ref([])
    const questionsLoading = ref(false)
    const questionsValid = ref(true)
    const questionsEdit = ref(false)
    const frozenOrders = ref([])

    localQuestions.value = _.cloneDeep(jobData.value.questions)
    if (localQuestions.value.length === 0 && vm.$can(ACTIONS.JOBS.MANAGE_JOB, jobData)) questionsEdit.value = true

    frozenOrders.value = Object.freeze(
      jobData.value.questions
        .filter((questionData) => Object.hasOwnProperty.bind(questionData)('order'))
        .map((questionData) => questionData.order),
    )

    const areQuestionsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(localQuestions.value, jobData.value.questions, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(jobData.value.questions, localQuestions.value, _.isEqual))
      )
    })

    const areQuestionsOrderChanged = computed(() => {
      return !_.isEqual(
        localQuestions.value
          .filter((questionData) => Object.hasOwnProperty.bind(questionData)('order'))
          .map((questionData) => questionData.order),
        frozenOrders.value,
      )
    })

    const handleQuestionsSubmit = async () => {
      if (!areQuestionsChanged) return

      if (!questionsValid.value) return

      questionsLoading.value = true

      localQuestions.value.forEach((questionData, index) => {
        if (index in frozenOrders.value) {
          // eslint-disable-next-line no-param-reassign
          questionData.order = frozenOrders.value[index]
        } else {
          // eslint-disable-next-line no-param-reassign
          delete questionData.order
        }
      })

      await updatedJobPart({
        jobId: jobData.value.id,
        payload: {
          questions: localQuestions.value,
        },
      })

      localQuestions.value = _.cloneDeep(jobData.value.questions)

      frozenOrders.value = Object.freeze(
        jobData.value.questions
          .filter((questionData) => Object.hasOwnProperty.bind(questionData)('order'))
          .map((questionData) => questionData.order),
      )

      questionsLoading.value = false
      questionsEdit.value = false
    }

    const restoreQuestions = () => {
      localQuestions.value = _.cloneDeep(jobData.value.questions)
      questionsEdit.value = false
    }

    return {
      handleQuestionsSubmit,
      restoreQuestions,
      addQuestion,
      deleteQuestion,
      onDragStart,
      onDragEnd,
      emptyValueFormatter,

      areQuestionsChanged,
      areQuestionsOrderChanged,

      dragOptions,
      jobData,
      localQuestions,
      questionsLoading,
      questionsValid,
      questionsEdit,

      validators: {
        required,
        minPositiveValue,
        maxDayValueWithEmptyValue,
      },

      icons: {
        mdiTrashCanOutline,
        mdiDrag,
        mdiPencilOutline,
      },
      ACTIONS,
    }
  },
}
</script>
